/* You can add global styles to this file, and also import other style files */
@import "ngx-toastr/toastr";
@import "primeicons/primeicons.css";
@import "primeng/resources/primeng.css";
@import "primeng/resources/themes/aura-light-noir/theme.css";

body,
html {
  margin: 0;
  box-sizing: border-box;
}

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

.p-progress-spinner-circle {
  stroke: #000 !important;
}

/* @layer primeng; */
/* @tailwind base;
@tailwind components;
@tailwind utilities; */
